<template>
  <div class="page flexBetweenUp">
    <div style="width: 49%; height: 100%; display: inline-block;vertical-align: top; ">
      <el-card>
        <div slot="header" class="clearfix">
          <span>仓库设置</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="addArea()">添加主区域</el-button>
        </div>
        <div class="linetree" style="">
          <el-tree class="tree" :highlight-current="true" :data="treeData" :props="defaultProps"
                   :default-expand-all="true"
                   :expand-on-click-node="false">

            <div class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <div style="float: right;">

                <i class="el-icon-edit" style="color: #00E0E0; margin-right: 10px;" title="编辑仓库"
                   @click="editArea(node)"></i>
                <i v-if="data.id!=-3" class="el-icon-delete" style="color: orangered; margin-right: 10px;"
                   title="删除仓库"
                   @click="deleteArea(node)"></i>
                <i v-if="data.id==-3" class="el-icon-delete"
                   style="color: #FFFFFF; margin-right: 10px;"></i>
                <i class="el-icon-circle-plus-outline" style="color: #0086B3; margin-right: 0px;"
                   @click="addArea(node)" title="添加下级仓库"></i>
              </div>
            </div>

          </el-tree>
        </div>
      </el-card>

    </div>

    <div v-show="dialogVisible" style=" width: 49%;margin-right: 1%;">
      <el-card>
<!--        <div slot="header" class="clearfix">-->
<!--          <span>仓库名称:{{ EditItem.parentname }}</span>-->

<!--        </div>-->
        <div>
          <el-form ref="form" :model="EditItem" label-width="80px">
            <el-form-item label="所属区域">
              <el-input v-model="EditItem.parentname" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="*仓库名称">
              <el-input v-model="EditItem.storename"></el-input>
            </el-form-item>


            <el-form-item label="*负责人:">
              <el-tag type="danger" v-if="EditItem.remark&&EditItem.remark.length>0"
                      v-for="(item,index) in EditItem.remark" style="margin-right: 10px;"
                      closable @close="deltag(index)">{{ item.name }}

              </el-tag>
              <i class="el-icon-circle-plus-outline" @click="showUser()"
                 style="color: #0086B3; margin-left: 10px; cursor: pointer;"
                 title="添加负责人"></i>
            </el-form-item>

          </el-form>
          <el-row>
            <el-col :span="24" style="text-align: right;">
              <el-button type="primary" plain @click="dialogVisible=false">取消</el-button>
              <el-button type="primary" @click="saveArea">保存</el-button>
            </el-col>
          </el-row>
        </div>

      </el-card>
    </div>


    <DeptUser :odata="OData" module="zichan" v-on:getUser="getUser" v-if="showUserDialog"></DeptUser>


  </div>
</template>

<script>
import DeptUser from '../../com/deptuser.vue'

export default {
  components: {
    DeptUser
  }, data() {

    return {
      defaultProps: {
        children: 'children',
        label: 'storename'
      },
      treeData: [],
      dialogVisible: false,
      EditItem: {storename: "", parentname: "", id: 0},
      prams: [],
      wxtype: [],//维修类型
      useall: true,
      OData: [],
      showUserDialog: false,
      wxUseridx: -1

    }
  },

  mounted() {
    setTimeout(() => {
      if (localStorage.getItem("user")) {
        this.userinfo = JSON.parse(localStorage.getItem("user"))
        this.getArea();
      } else {
        // window.reload
        this.getArea();
      }
    }, 500)
  },
  methods: {
    getArea() {
      this.$http.post("/api/zc_store").then(res => {
        this.treeData = res.data;
      })
    },
    getBXParams() {
      this.$http.post("/api/zichan_params").then(res => {
        this.prams = res.data;
        for (let item of res.data) {
          if (item.ename == "wxtype") {
            item.user = null
            this.wxtype.push(item)
          }
        }
      })

    },
    showNode() {

    },
    addArea(node) {
      console.log(node)
      if (node) {
        this.EditItem = {
          storename: "",
          parentname: node.data.storename,
          parentid: node.data.id,
          remark: JSON.parse(node.data.remark)
          //这里阿宽说要一个个设置区域的人，所以要留空
          //灿灿说这里父节点设置了负责人，增加子节点的时候默认把父节点的负责人带到子节点；2022-08-19上午，解决申领耗材时找不到仓管，数据已固化，再设置仓管无效
        }
      } else {
        this.EditItem = {
          storename: "",
          parentname: "",
          parentid: 0,
          remark: []
        }
      }
      this.dialogVisible = true

    },
    editArea(node) {
      this.EditItem = {
        id: node.data.id,
        storename: node.data.storename,
        parentname: node.data.parentid ? node.parent.data.storename : '',
        parentid: node.data.parentid,
        remark: node.data.remark ? JSON.parse(node.data.remark) : []
      }
      console.log(this.EditItem.remark)
      this.dialogVisible = true
    },
    deleteArea(node) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/zc_store_delete", {
          id: node.data.id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          this.getArea();
        })

      }).catch(() => {

      });
    },
    showUser() {

      if (this.EditItem.remark && this.EditItem.remark.length > 0) {
        this.OData = this.EditItem.remark
      } else {
        this.OData = [];
      }
      this.showUserDialog = true;
    },
    getUser(e) {
      console.log(e)
      if (e && e.length > 0) {
        let t = e[0];
        let teacher = {
          union_id: t.id,
          name: t.username,
          avatarUrl: t.avatarUrl,
          dept_id: t.dept_id
        }
        let arr = (this.EditItem.remark && this.EditItem.remark.length > 0) ? JSON.parse(JSON.stringify(this.EditItem.remark)) : []
        if (arr && arr.length > 0) {
          let names = arr.map((item) => item.union_id)
          console.log(names)
          console.log(teacher.union_id)
          console.log(names.indexOf(teacher.union_id))
          if (names.indexOf(teacher.union_id) < 0) {
            arr.push(teacher)
          }
        } else {
          arr.push(teacher)
        }
        this.EditItem.remark = arr;
      }

      setTimeout(() => {
        this.showUserDialog = false;
      }, 300)
    },
    deltag(idx){
      this.EditItem.remark.splice(idx,1)
    },
    saveArea() {
      console.log(this.EditItem)
      console.log(this.wxtype)

      let data = JSON.parse(JSON.stringify(this.EditItem))
      if (!data.remark||data.remark.length==0) {
        this.$message.warning('请先设置仓库负责人！')
        return
      }else {
        data.remark = JSON.stringify(data.remark)

      }
      this.$http.post("/api/zc_store_save", data).then(res => {
        this.$message({
          type: 'success',
          message: '保存成功'
        });
        this.getArea()
        this.dialogVisible = false
      })


    }


  }
}
</script>
<style scoped="scoped">
.custom-tree-node {
  width: 100%;
}
</style>
<style lang="less" type="text/scss" scoped="scoped">
::v-deep .linetree {
  max-height: 680px;
  .el-tree > .el-tree-node > .el-tree-node__content:first-child {

    &::before,
    &::after {
      border: none;
    }
  }

  .el-tree-node .el-tree-node__content {
    height: 30px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      right: auto;
    }

    &::before {
      border-left: 1px solid #c0c4cc;
      bottom: 50px;
      height: 100%;
      top: 0;
      width: 1px;
      margin-left: -5px;
      margin-top: -15px;
      z-index: 9;
    }

    &::after {
      border-top: 1px solid #c0c4cc;
      height: 20px;
      top: 14px;
      width: 10px;
      margin-left: -5px;
      z-index: 9;
    }
  }

  .el-tree .el-tree-node {
    position: relative;
  }
}
::v-deep .el-card__body {
  overflow-y: auto;
  //height: 600px;
}
</style>
